import api from "../../api/axios.instance"

export const getUserprofile = async () => {
    try {
        const response = await api.get('common/profile')
        return response
    } catch (error) {
        return error
    }
}
export const editProfile = async (data) => {
    try {
        const response = await api.put('common/profile', data)
        return response
    } catch (error) {
        return error
    }
}
export const changePaswd = async (data) => {
    try {
        const response = await api.post('common/update-user-password', data)
        return response
    } catch (error) {
        return error
    }
}
export const getCompanyDetails=async()=>{
    try {
        const response = await api.get('admin/company-profile')
        return response
    } catch (error) {
        return error
    }
}
export const updateCompanyDetails = async (data) => {
    try {
        const response = await api.put('admin/company-profile', data)
        return response
    } catch (error) {
        return error
    }
}
