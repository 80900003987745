import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react';
import Profileschema from '../../schema/profile';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import ProfileApis from '../../queries/profile';
import { useOutletContext } from 'react-router-dom';
import toast from'react-hot-toast'
function EditProfile({ profileData, getProfiledata }) {

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            firstName: profileData?.data?.user_detail?.firstName ?? "hai",
            about: profileData?.data?.about,
            email: profileData?.data?.email,
            lastName: profileData?.data?.user_detail?.lastName,
            mobile: profileData?.data?.user_detail?.mobile
        },
        resolver: yupResolver(Profileschema),
    })
    const { getAdminDetails } = useOutletContext();
    const editProfile = ProfileApis.Editprofile()
    const [file, setfile] = useState(null)
    const [imgPreview, setimgPreview] = useState()

    const editHandler = async (data) => {
        const formData = new FormData()
        formData.append('file', file)
        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value)
        })
        const response = await editProfile.mutateAsync(formData)
        if (response.status) {
            await getProfiledata()
            await getAdminDetails()
            toast.success('personal details updated')
        }else{
            toast.error(response?.error?.message)
        }
    }
    const fileChangehandler = (e) => {
        setfile(e.target.files[0])
        setimgPreview(URL.createObjectURL(e.target.files[0]))
    }
    useEffect(() => {
        setimgPreview(profileData?.data?.user_detail?.profilePhoto)
    }, [profileData])
    return (
        <div className="tab-pane fade active show" id="pills-edit-profile" role="tabpanel" aria-labelledby="pills-edit-profile-tab" tabIndex="0">
            <h6 className="text-md text-primary-light mb-16">Profile Image</h6>

            {/* Upload Image Start */}
            <div className="mb-24 mt-16">
                <div className="avatar-upload">
                    <div className="avatar-edit position-absolute bottom-0 end-0 me-24 mt-16 z-1 cursor-pointer">
                        <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" hidden onChange={fileChangehandler} />
                        <label
                            htmlFor="imageUpload"
                            className="w-32-px h-32-px d-flex justify-content-center align-items-center bg-primary-50 text-primary-600 border border-primary-600 bg-hover-primary-100 text-lg rounded-circle"
                        >
                            <Icon icon="solar:camera-outline" className="icon" />
                        </label>
                    </div>
                    <div className="avatar-preview">
                        <div id="imagePreview"> <img src={imgPreview ?? '/image/user.png'} /></div>
                    </div>
                </div>
            </div>
            {/* Upload Image End */}

            <form action="#" onSubmit={handleSubmit(editHandler)}>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="mb-20">
                            <label htmlFor="firstName" className="form-label fw-semibold text-primary-light text-sm mb-8">
                                First Name <span className="text-danger-600">*</span>
                            </label>
                            <input
                                type="text"
                                className={`form-control radius-8 ${errors?.firstName ? 'is-invalid' : ''}`}
                                {...register('firstName')}
                                id="firstName"
                                placeholder="Enter First Name"
                            />
                            <div className="invalid-feedback">
                                {errors?.firstName?.message}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-20">
                            <label htmlFor="lastName" className="form-label fw-semibold text-primary-light text-sm mb-8" >
                                Last Name
                            </label>
                            <input
                                type="text"
                                className={`form-control radius-8 ${errors?.lastName ? 'is-invalid' : ''}`}
                                {...register('lastName')}
                                id="lastName"
                                placeholder="Enter Last Name"
                            />
                            <div className="invalid-feedback">
                                {errors?.lastName?.message}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-20">
                            <label htmlFor="email" className="form-label fw-semibold text-primary-light text-sm mb-8">
                                Email <span className="text-danger-600">*</span>
                            </label>
                            <input
                                type="email"
                                className={`form-control radius-8 ${errors?.email ? 'is-invalid' : ''}`}
                                {...register('email')}
                                id="email"
                                placeholder="Enter email address"
                            />
                            <div className="invalid-feedback">
                                {errors?.email?.message}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-20">
                            <label htmlFor="mobile" className="form-label fw-semibold text-primary-light text-sm mb-8">
                                Phone
                            </label>
                            <input
                                type="text"
                                className={`form-control radius-8 ${errors?.mobile ? 'is-invalid' : ''}`}
                                {...register('mobile')}
                                id="mobile"
                                placeholder="Enter phone number"
                            />
                            <div className="invalid-feedback">
                                {errors?.mobile?.message}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="mb-20">
                            <label htmlFor="about" className="form-label fw-semibold text-primary-light text-sm mb-8">
                                About <span className="text-danger-600">*</span>
                            </label>
                            <textarea
                                className={`form-control radius-8 ${errors?.about ? 'is-invalid' : ''}`}
                                {...register('about')}
                                id="about"
                                placeholder="Write description..."
                            ></textarea>
                            <div className="invalid-feedback">
                                {errors?.about?.message}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center gap-3">
                    <button
                        type="button"
                        className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-56 py-11 radius-8"
                        onClick={()=>reset()}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn btn-primary border border-primary-600 text-md px-56 py-12 radius-8"
                    >
                        Save
                    </button>
                </div>
            </form>

        </div>

    )
}

export default EditProfile