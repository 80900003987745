import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import logo from '../assets/images/logo.png'
import logoLight from '../assets/images/logo-light.png'
import logoIcon from '../assets/images/logo-icon.png'
import { NavLink } from "react-router-dom";
import { useAuth } from '../context/AuthContext';

export default function SideMenu() {
  const { userType } = useAuth()
  let adminMenus = [
    {
      title: 'Dashboard',
      icon: 'solar:home-smile-angle-outline',
      path: '/dashboard'
    },
    {
      title: 'Tutor',
      icon: 'hugeicons:teacher',
      path: '/tutors'
    },
    {
      title: 'Student',
      icon: 'ph:user',
      path: '/students'
    },
    {
      title: 'Courses',
      icon: 'hugeicons:course',
      path: '/course'
    },
    {
      title: 'Sales Report',
      icon: 'carbon:sales-ops',
      path: '/sales-report'
    },
    {
      title: 'User Approval',
      icon: 'material-symbols-light:order-approve-outline-sharp',
      path: '/user-approval'
    },
    // {
    //   title: 'Financial Record',
    //   icon: 'material-symbols-light:finance-mode',
    //   path: '/financial-record'
    // },
    // {
    //   title: 'Report',
    //   icon: 'material-symbols-light:finance-sharp',
    //   path: '/report'
    // }
  ]
  const [menus, setmenus] = useState(adminMenus)
  useEffect(() => {
    if (userType === "tutor") {
      const menus = adminMenus.filter((obj) => obj.title != "Tutor" && obj.title != "User Approval")
      setmenus(menus)
    }
  }, [userType])

  return (
    <aside className="sidebar">
      <div>
        <a href="index.html" className="sidebar-logo">
          <img src='/image/company_logo.jpg' alt="site logo" className="light-logo" />
          <img src={logoLight} alt="site logo" className="dark-logo" />
          <img src={logoIcon} alt="site logo" className="logo-icon" />
        </a>
      </div>
      <div className="sidebar-menu-area">
        <ul className="sidebar-menu" id="sidebar-menu">
          {
            menus.map((item, index) => {
              return (
                <li key={index}>  {/* Add a unique key here */}
                  <NavLink
                    to={item.path}
                    className={({ isActive, isPending }) =>
                      isPending ? "pending" : isActive ? "active" : ""
                    }
                  >
                    <Icon icon={item.icon} className='menu-icon' />
                    <span>{item.title}</span>
                  </NavLink>
                </li>
              );
            })

          }

          {/* <li>
            <a href="tutors.html">
              <Icon icon="hugeicons:teacher" className='menu-icon' />
              <span>Tutor</span>
            </a>
          </li>
          <li>
            <a href="students.html">
              <Icon icon="ph:user" className='menu-icon' />
              <span>Students</span>
            </a>
          </li>
          <li>
            <a href="courses.html">
              <Icon icon="hugeicons:course" className='menu-icon' />
              <span>Courses</span>
            </a>
          </li>
          <li>
            <a href="financial-record.html">
              <Icon icon="material-symbols-light:finance-mode" className='menu-icon' />
              <span>Financial Record</span>
            </a>
          </li> */}
          {/* <li>
            <a href="message.html">
              <Icon icon="system-uicons:message" className='menu-icon' />
              <span>Message</span>
            </a>
          </li> */}
          {/* <li>
            <a href="#">
              <Icon icon="hugeicons:coupon-01" className='menu-icon' />
              <span>Coupons</span>
            </a>
          </li> */}
          {/* <li>
            <a href="#">
              <Icon icon="material-symbols-light:finance-sharp" className='menu-icon' />
              <span>Report</span>
            </a>
          </li> */}
        </ul>
      </div>
    </aside>

  )
}
