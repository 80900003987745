import api from "../../api/axios.instance"

export const getHighRateCourses = async (limit) => {
    try {
        const response = await api.get('common/top-courses', { params: { limit } })
        return response
    } catch (error) {
        return error
    }
}

export const getCourseTableData = async (start, length, status, name) => {
    try {
        const response = await api.get('common/course', { params: { start, length, status, name } })
        return response
    } catch (error) {
        return error
    }
}
export const getCategories = async () => {
    try {
        const response = await api.get('categories')
        return response
    } catch (error) {
        return error
    }
}
export const getCourseLevel = async () => {
    try {
        const response = await api.get('course-levels')
        return response
    } catch (error) {
        return error
    }
}
export const addCourse = async (data) => {
    try {
        const response = await api.post('common/course', data)
        return response
    } catch (error) {
        return error
    }
}
export const editCourse = async (data) => {
    try {
        const response = await api.put('common/course', data)
        return response
    } catch (error) {
        return error
    }
}
export const getCourseModule = async (courseId, page, length) => {
    try {
        const response = await api.get('common/course-details-list', { params: { courseId, page, length } })
        return response
    } catch (error) {
        return error
    }
}
export const dltTask = async (data) => {
    try {
        const response = await api.post('common/delete-task', data)
        return response
    } catch (error) {
        return error
    }
}
export const addNewTask = async (data) => {
    try {
        const response = await api.post('common/task', data)
        return response
    } catch (error) {
        return error
    }
}
export const editTask = async (data) => {
    try {
        const response = await api.put('common/task', data)
        return response
    } catch (error) {
        return error
    }
}
export const addNewModule = async (data) => {
    try {
        const response = await api.post('common/module', data)
        return response
    } catch (error) {
        return error
    }
}
export const EditModule=async(data)=>{
    try {
        const response = await api.put('common/module', data)
        return response
    } catch (error) {
        return error
    }
}