import axios from 'axios'
import { BASE_URL } from '../config';
import { useAuth } from '../context/AuthContext';

const api = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': 'true',
        'role': 'admin'
    },
})
api.interceptors.request.use(function (config) {

    if (config.data instanceof FormData) {
        // Update headers for multipart/form-data requests
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});
api.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
}, function (error) {
    if (error?.response?.data?.code === 4007 || error?.response?.data?.code === 4018) {
        localStorage.clear()
        window.location.href = "/login"
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error.response.data);
});
export default api