import { Icon } from '@iconify/react'
import React, { useEffect, useState } from 'react'
import RevenueReport from './RevenueReport'
import Tiles from './Tiles'
import StudentStatistics from './StudentStatistics'
import RecentPurchases from './RecentPurchases'
import Transactions from './Transactions'
import TopTutors from './TopTutors'
import TopStudents from './TopStudents'
import DashboardApis from '../../queries/dashboard'
import Courses from '../tutor-dashboard/Courses'
import CourseApis from '../../queries/course'
export default function MainDashboard() {
    const [period, setperiod] = useState('month')
    const [transactionPeriod, setTransactionPeriod] = useState('current')
    const [revenuePeriod, setrevenuePeriod] = useState('year')
    const { data: tiles } = DashboardApis.GetTiles()
    const { data: dountChartData } = DashboardApis.StudentStatics(period)
    const { data: recentPurchaseData } = DashboardApis.RecentPurchase()
    const { data: topTutors } = DashboardApis.Toptutors()
    const { data: topStudents } = DashboardApis.TopStudents()
    const { data: transactionsData } = DashboardApis.Transactions(transactionPeriod)
    const { data: RevenueReportData } = DashboardApis.RevenueReport(revenuePeriod)
    const { data: CourseData } = CourseApis.GetHighRateCourses()
    return (
        <div class="dashboard-main-body" data-aos="fade-right">
            <div class="row gy-4">

                <div className="col-xxl-9">
                    <div className="card radius-8 border-0">
                        {/* <span></span> */}
                        <div className="row">
                            {
                                RevenueReportData?.data &&
                                <RevenueReport
                                    RevenueReportData={RevenueReportData?.data}
                                    revenuePeriod={revenuePeriod}
                                    setrevenuePeriod={setrevenuePeriod}
                                />
                            }

                            <Tiles
                                tilesData={tiles?.data}
                            />
                        </div>
                    </div>
                </div>
                {
                    dountChartData?.data &&
                    <StudentStatistics
                        dountChartData={dountChartData?.data}
                        setperiod={setperiod}
                        period={period}
                    />
                }

                <RecentPurchases recentPurchaseData={recentPurchaseData?.data} />
                {
                    transactionsData?.data && <Transactions
                        transactionsData={transactionsData?.data}
                        setTransactionPeriod={setTransactionPeriod}
                        transactionPeriod={transactionPeriod}
                    />
                }
                <Courses CourseData={CourseData?.data} />
                {/* <TopTutors topTutorsData={topTutors?.data} /> */}
                <TopStudents topStudents={topStudents?.data} />
            </div>
        </div>

    )
}
