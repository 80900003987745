import api from "../../api/axios.instance"

export const approveUser = async (data) => {
    try {
        const response = await api.post('admin/pending-register', data)
        return response
    } catch (error) {
        return error
    }
}
export const getPendingUsersList = async (page, email, length, role) => {
    try {
        const response = await api.get('admin/pending-register', { params: { page, email, length, role } })
        return response
    } catch (error) {
        return error
    }
}