import { useMutation, useQuery } from '@tanstack/react-query'
import { EditModule, addCourse, addNewModule, addNewTask, dltTask, editCourse, editTask, getCategories, getCourseLevel, getCourseModule, getCourseTableData, getEditfieldvalues, getHighRateCourses } from '../service/course/courseService';


const CourseApis = {
    GetHighRateCourses: (limit = 5) => {
        const response = useQuery({
            queryKey: ['get-high-rate-courses', limit],
            queryFn: () => getHighRateCourses(limit)
        })
        return response;
    },
    GetCourseTableData: (start, length, status, name) => {
        const response = useQuery({
            queryKey: ['get-course-table-data', start, length, status, name],
            queryFn: () => getCourseTableData(start, length, status, name)
        })
        return response;
    },
    Getcategories: () => {
        const response = useQuery({
            queryKey: ["get-categories"],
            queryFn: () => getCategories()
        })
        return response
    },
    GetCourseLevel: () => {
        const response = useQuery({
            queryKey: ["get-course-level"],
            queryFn: () => getCourseLevel()
        })
        return response
    },
    AddCourse: () => {
        const response = useMutation({
            mutationFn: (data) => addCourse(data)
        })
        return response
    },
    EditCourse: () => {
        const response = useMutation({
            mutationFn: (data) => editCourse(data)
        })
        return response
    },
    GetCourseModule: (courseId, page, length) => {
        const response = useQuery({
            queryKey: ["get-course-level", courseId, page, length],
            queryFn: () => getCourseModule(courseId, page, length)
        })
        return response
    },
    DltTask: () => {
        const response = useMutation({
            mutationFn: (data) => dltTask(data)
        })
        return response
    },
    AddNewTask:()=>{
        const response = useMutation({
            mutationFn: (data) => addNewTask(data)
        })
        return response
    },
    EditTask:()=>{
        const response = useMutation({
            mutationFn: (data) => editTask(data)
        })
        return response
    },
    AddNewModule:()=>{
        const response = useMutation({
            mutationFn: (data) => addNewModule(data)
        })
        return response
    },
    EditModule:()=>{
        const response = useMutation({
            mutationFn: (data) => EditModule(data)
        })
        return response
    },




}
export default CourseApis;