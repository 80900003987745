import * as yup from "yup"

const TaskSchema = yup
    .object({
        title: yup.string().required(),
        videoLength:yup.string().required("Duration is required field").nullable(),
        url: yup.string().required().matches(/^(ftp|http|https):\/\/[^ "]+$/,'Invalid url'),   
    })
    .required()

export default TaskSchema