import React, { useState } from 'react'
import PendingUsersTable from '../../components/pendingUsers/PendingUsersTable'
import UserAprovalApis from '../../queries/pending.users'
import UserApprovalModal from '../../components/pendingUsers/UserApprovalModal'
function PendingUsers() {
    const [page, setpage] = useState(1)
    const [email, setEmail] = useState('')
    const [length, setlength] = useState(10)
    const [userType, setuserType] = useState('')
    const [showApproveModal, setshowApproveModal] = useState(false)
    const [ApprovalData, setApprovalData] = useState({})
    const { data: pendingUsers, refetch: getPendingUsers, isLoading: apiIsFetching } = UserAprovalApis.GetPendingUserList(page, email, length, userType)
    console.log("==pendingUsers==", pendingUsers)
    const openApproveModal = ({ data }) => {
        setshowApproveModal(true)
        setApprovalData({
            ...ApprovalData,
            data
        })
    }
    const handleClose = () => {
        setshowApproveModal(false)
    }
    console.log("======ApprovalData=====",ApprovalData)
    return (
        <div className='dashboard-main-body'>

            <PendingUsersTable
                pendingUsers={pendingUsers}
                page={page}
                setpage={setpage}
                length={length}
                userType={userType}
                email={email}
                setuserType={setuserType}
                setEmail={setEmail}
                getPendingUsers={getPendingUsers}
                apiIsFetching={apiIsFetching}
                openApproveModal={openApproveModal}
            />
            {
                ApprovalData &&
                <UserApprovalModal
                    show={showApproveModal}
                    handleClose={handleClose}
                    ApprovalData={ApprovalData}
                    getPendingUsers={getPendingUsers}
                />
            }



        </div>
    )
}

export default PendingUsers