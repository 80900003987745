import { useMutation, useQuery } from '@tanstack/react-query'
import { approveUser, getPendingUsersList } from '../service/pending-users/pendingUsersService'

const UserAprovalApis = {
    ApproveUser: () => {
        const response = useMutation({
            mutationFn: (data) => approveUser(data),

        })
        return response
    },
    GetPendingUserList: (page,username,length,userType) => {
        const response = useQuery({
            queryKey: ["get-pending-user-list",page,username,length,userType],
            queryFn: () => getPendingUsersList(page,username,length,userType)
        })
        return response
    },
}
export default UserAprovalApis;