import api from "../../api/axios.instance";

export const getAllTutors = async (start, length, status, name) => {
    try {
        const response = await api.get('admin/all-tutors', { params: { start, length, status, name } })
        return response
    } catch (error) {
        return error
    }
}

export const changeTutorStatus = async (data) => {
    try {
        const response = await api.post('admin/update-user-status', data)
        return response
    } catch (error) {
        return error
    }
}
export const AddTutor = async (data) => {
    try {
        const response = await api.post('register-user', data)
        return response
    } catch (error) {
        return error
    }
}
export const getRegCourse = async () => {
    try {
        const response = await api.get('admin/register-course-list')
        return response
    } catch (error) {
        return error
    }
}
export const editTutorDetails = async (data) => {
    try {
        const response = await api.post('admin/update-user-profile', data)
        return response
    } catch (error) {
        return error
    }
}
export const getTutorDetails = async (userId) => {
    try {
        const response = await api.get('admin/user-details', { params: { userId } })
        return response
    } catch (error) {
        return error
    }
}


export const getTiles = async () => {
    try {
        const response = await api.get('tutor/dashboard-tiles')
        return response
    } catch (error) {
        return error
    }
}
export const getTutorStudentStatics = async (period) => {
    try {
        const response = await api.get('tutor/student-statistics', { params: { period } })
        return response
    } catch (error) {
        return error
    }
}
export const getTutorCoursePurchaseList = async (start, length, name) => {
    try {
        const response = await api.get('common/recent-purchases', { params: { start, length, username:name } })
        return response
    } catch (error) {
        return error
    }
}
export const getTutorStudentsList = async (limit) => {
    try {
        const response = await api.get('common/top-students', { params: { limit } })
        return response
    } catch (error) {
        return error
    }
}
export const getTutorTransactionList= async (type) => {
    try {
        const response = await api.get('tutor/transactions', { params: { type } })
        return response
    } catch (error) {
        return error
    }
}
export const getTutorCourseList=async (limit) => {
    try {
        const response = await api.get('tutor/top-tutors', { params: { limit } })
        return response
    } catch (error) {
        return error
    }
}
