import React, { useState } from 'react'
import { Icon } from '@iconify/react';
import PasswordSchema from '../../schema/password';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ProfileApis from '../../queries/profile';
import toast from 'react-hot-toast'
function ChangePassword() {
    const [eyeOpen, seteyeOpen] = useState(false)
    const [confirmEye, setconfirmEye] = useState(false)
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(PasswordSchema),
    })
    const changePassword = ProfileApis.ChangePswd()
    const submitHandler = async (data) => {
        const response = await changePassword.mutateAsync(data)
        if (response.status) {
            reset({
                password: '',
                confirmPaswd: '',
            });

            toast.success('password reset successful')
        } else {
            toast.error('password reset failed')
        }
    }
    return (
        <div className="tab-pane fade" id="pills-change-passwork" role="tabpanel" aria-labelledby="pills-change-passwork-tab" tabIndex="0">
            <div className="mb-20">
                <label htmlFor="your-password" className="form-label fw-semibold text-primary-light text-sm mb-8">
                    New Password <span className="text-danger-600">*</span>
                </label>
                <div className="position-relative">
                    <input
                        type={`${eyeOpen ? 'text' : 'password'}`}
                        className={`form-control radius-8 ${errors?.password ? 'is-invalid' : ''}`}
                        id="your-password"
                        placeholder="Enter New Password*"
                        {...register('password')}
                    />

                    {/* <span
                        className="toggle-password ri-eye-line cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light"
                        data-toggle="#your-password"
                    >

                    </span> */}

                    <div hidden={errors?.password ? true : false}>
                        {
                            eyeOpen ?

                                <Icon icon="fluent:eye-28-regular" className='toggle-password ri-eye-line cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light' width="24" height="24" onClick={() => seteyeOpen(!eyeOpen)}
                                />
                                :
                                <Icon icon="fluent:eye-off-16-regular" width="24" height="24" className='toggle-password ri-eye-line cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light' onClick={() => seteyeOpen(!eyeOpen)}
                                />
                        }

                    </div>


                    <div className="invalid-feedback">
                        {errors?.password?.message}
                    </div>

                </div>
            </div>
            <div className="mb-20">
                <label htmlFor="confirm-password" className="form-label fw-semibold text-primary-light text-sm mb-8">
                    Confirm Password <span className="text-danger-600">*</span>
                </label>
                <div className="position-relative">
                    <input
                        type={`${confirmEye ? 'text' : 'password'}`}
                        className={`form-control radius-8 ${errors?.confirmPaswd ? 'is-invalid' : ''}`}
                        id="confirm-password"
                        placeholder="Confirm Password*"
                        {...register('confirmPaswd')}
                    />
                    {/* <span
                        className="toggle-password ri-eye-line cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light"
                        data-toggle="#confirm-password"
                    ></span> */}
                    <div hidden={errors?.confirmPaswd ? true : false}>
                        {
                            confirmEye ?
                                <Icon icon="fluent:eye-28-regular" className='toggle-password ri-eye-line cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light' width="24" height="24" onClick={() => setconfirmEye(!confirmEye)} />
                                :
                                <Icon icon="fluent:eye-off-16-regular" className='toggle-password ri-eye-line cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light' width="24" height="24" onClick={() => setconfirmEye(!confirmEye)} />
                        }
                    </div>


                    <div className="invalid-feedback">
                        {errors?.confirmPaswd?.message}
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-center gap-3">
                <button
                    type="button"
                    className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-56 py-11 radius-8"
                    onClick={() => reset({ password: '', confirmPaswd: '' })}
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="btn btn-primary border border-primary-600 text-md px-56 py-12 radius-8"
                    onClick={handleSubmit(submitHandler)}
                >
                    Save
                </button>
            </div>
        </div>

    )
}

export default ChangePassword