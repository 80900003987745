import React from 'react'
import { formatNumber } from '../../utils/formatNumber'
function CourseDetails({ moduleData }) {
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-floating mb-3">
                            <input
                                type="email"
                                className="form-control"
                                id="floatingInputCourseName"
                                value={moduleData?.data?.course?.title}
                                readOnly
                            />
                            <label htmlFor="floatingInputCourseName">Course Name</label>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-floating mb-3">
                            <input
                                type="email"
                                className="form-control"
                                id="floatingInputPrice"
                                value={`$${formatNumber(moduleData?.data?.course?.price)}`}
                                readOnly
                            />
                            <label htmlFor="floatingInputPrice">Price</label>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-floating mb-3">
                            <input
                                type="email"
                                className="form-control"
                                id="floatingInputTotalHours"
                               value={`${(moduleData?.data?.totalDuration / 3600).toFixed(2)}hr`}
                                readOnly
                            />
                            <label htmlFor="floatingInputTotalHours">Total Hours</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseDetails