import React from 'react'
import Pagination from '../../common/Pagination'
import { Icon } from '@iconify/react'
import noDataimg from '../../assets/images/No data-cuate.png'
import TableSkeleton from '../../utils/skeletons/TableSkeleton'

function PendingUsersTable({
    pendingUsers,
    page,
    setpage,
    length,
    userType,
    email,
    setuserType,
    setEmail,
    apiIsFetching,
    getPendingUsers,
    openApproveModal
}) {
    return (
        <div className="card h-100">
            <div className="card-header border-bottom bg-base py-16 px-24 d-flex align-items-center flex-wrap gap-3 justify-content-between">
                <div className="d-flex align-items-center flex-wrap gap-3">
                    <form className="navbar-search">
                        <input type="text" className="bg-base h-40-px w-auto" name="search" placeholder="Enter email" value={email}
                            onChangeCapture={(e) => setEmail(e.target.value)} />
                        <Icon icon="ion:search-outline" className="icon"></Icon>
                    </form>
                    <select className="form-select form-select-sm w-auto ps-12 py-6 radius-12 h-40-px"
                        value={userType}
                        onClick={(e) => setuserType(e.target.value)}>
                        {
                            [{ title: 'Type', value: '' },
                            { title: 'Student', value: 'student' },
                            { title: 'Tutor', value: 'tutor' }].map((item) => (
                                <option value={item.value}>{item.title}</option>
                            ))
                        }
                    </select>
                </div>

            </div>
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-style mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Fullname</th>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Mobile</th>
                                <th>Date</th>
                                <th>Approve</th>
                            </tr>
                        </thead>
                        {
                            apiIsFetching ?
                                <TableSkeleton rowCount={6} colCount={6} />
                                :
                                <tbody>
                                    {
                                        pendingUsers?.data?.data?.length > 0 ?
                                            pendingUsers?.data?.data?.map((item, index) => {
                                                const tableIndex = (page - 1) * length + index + 1
                                                return (
                                                    <tr>
                                                        <td>{tableIndex}</td>
                                                        <td>{item.details.firstName + item.details.lastName}</td>
                                                        <td>{item.details.username}</td>
                                                        <td>{item.details.email}</td>
                                                        <td>{item.details.role}</td>
                                                        <td>{item.details.mobile}</td>
                                                        <td>{item.date}</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-10">
                                                                <button type="button" className="card-edit-button text-success-600"
                                                                    onClick={() => openApproveModal({ data: { id: item.id, email: item.details.email,role:item.details.role } })}
                                                                >
                                                                    <Icon icon="lucide:edit" className="icon text-lg line-height-1"></Icon>
                                                                </button>
                                                                {/* {
                                                                    item.active ?
                                                                        <OverlayTrigger
                                                                            placement='right'
                                                                            delay={{ show: 250, hide: 400 }}
                                                                            overlay={
                                                                                <Tooltip>
                                                                                    <span>Block user</span>
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <button type="button" className="card-delete-button text-danger-600"
                                                                                onClick={() => changeStudentstatus('block', item.id)}>
                                                                                <Icon icon="fluent:delete-24-regular" className="icon text-lg line-height-1"></Icon>
                                                                            </button>
                                                                        </OverlayTrigger>

                                                                        :
                                                                        <OverlayTrigger
                                                                            placement='right'
                                                                            delay={{ show: 250, hide: 400 }}
                                                                            overlay={
                                                                                <Tooltip>
                                                                                    <span>Click to unblock</span>
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <button type="button" className="card-delete-button text-danger-600"
                                                                                onClick={() => changeStudentstatus('unblock', item.id)}>
                                                                                <Icon icon="mdi:person-block" className="icon text-lg line-height-1"></Icon>

                                                                            </button>
                                                                        </OverlayTrigger>

                                                                } */}

                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan={5} className="text-center">
                                                    <img src={noDataimg} className='w-50' />
                                                </td>
                                            </tr>
                                    }

                                </tbody>
                        }

                    </table>
                </div>
                <Pagination
                    totalEntries={pendingUsers?.data?.totalCount}
                    entriesPerPage={length}
                    currentPage={page}
                    onPageChange={setpage}
                />

            </div>
        </div>

    )
}

export default PendingUsersTable