import React from 'react'
import SideMenu from '../common/SideMenu'
import Header from '../common/Header'
import Footer from '../common/Footer'
import { Outlet } from 'react-router-dom'
import MainDashboard from '../components/dashboard/MainDashboard'
import $ from 'jquery';
import { useEffect } from 'react'
import Tutors from '../view/tutor/Tutors'
import Student from '../view/student/Student'
import Aos from 'aos'
import { useLocation } from 'react-router-dom'
import DashboardApis from '../queries/dashboard'
export default function MainLayout() {
  useEffect(() => {
    Aos.init()
  }, []);

  useEffect(() => {
    // Sidebar submenu collapsible logic
    $(".sidebar-menu .dropdown").on("click", function () {
      const item = $(this);
      item.siblings(".dropdown").children(".sidebar-submenu").slideUp();
      item.siblings(".dropdown").removeClass("dropdown-open open");
      item.children(".sidebar-submenu").slideToggle();
      item.toggleClass("dropdown-open");
    });
  
    // Sidebar toggle logic
    $(".sidebar-toggle").on("click", function () {
      $(this).toggleClass("active");
      $(".sidebar").toggleClass("active");
      $(".dashboard-main").toggleClass("active");
    });
  
    // Sidebar mobile toggle logic
    $(".sidebar-mobile-toggle").on("click", function (e) {
      e.stopPropagation(); // Prevent document click event from firing
      const sidebar = $(".sidebar");
  
      if (sidebar.hasClass("sidebar-open")) {
        // Sidebar is open, close it
        sidebar.removeClass("sidebar-open");
        $("body").removeClass("overlay-active");
      } else {
        // Sidebar is closed, open it
        sidebar.addClass("sidebar-open");
        $("body").addClass("overlay-active");
      }
    });
  
    // Sidebar close button logic
    $(".sidebar-close-btn").on("click", function () {
      $(".sidebar").removeClass("sidebar-open");
      $("body").removeClass("overlay-active");
    });
  
    // Close sidebar when clicking outside (in mobile view)
    $(document).on("click", function (e) {
      const sidebar = $(".sidebar");
  
      // Check if the click was outside the sidebar and not on the toggle button
      if (!sidebar.is(e.target) && sidebar.has(e.target).length === 0) {
        sidebar.removeClass("sidebar-open");
        $("body").removeClass("overlay-active");
      }
    });
  
    // Cleanup event listeners on component unmount
    return () => {
      $(".sidebar-menu .dropdown").off();
      $(".sidebar-toggle").off();
      $(".sidebar-mobile-toggle").off();
      $(".sidebar-close-btn").off();
      $(document).off("click");
    };
  }, []);
  
  
   // Empty dependency array ensures this runs only on mount
  const { data: adminDetails, refetch: getAdminDetails } = DashboardApis.GetAdmindetails()
  return (
    <>
      <SideMenu />
      <main className="dashboard-main" >
        <Header adminDetails={adminDetails} />
        <Outlet context={{ getAdminDetails }} />
        <Footer />
      </main>
    </>
  )
}
