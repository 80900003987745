import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import StudentApis from '../../queries/student';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import StudentEditschema from '../../schema/student.edit';
import toast from 'react-hot-toast'
import UserAprovalApis from '../../queries/pending.users';

function UserApprovalModal({ show, handleClose, ApprovalData,getPendingUsers }) {
    console.log("===ApprovalData==ff", ApprovalData?.data)
    const approveUser = UserAprovalApis.ApproveUser()
    const {
        register,
        handleSubmit,
        trigger,
        reset,
        setError,
        setValue,
        formState: { errors },
    } = useForm({
    })

    useEffect(() => {
        setValue('email', ApprovalData?.data?.email)
        setValue('userRole', ApprovalData?.data?.role)
    }, [ApprovalData])
    const submitHandler = async (data) => {
        data.id = ApprovalData?.data?.id
        const response = await approveUser.mutateAsync(data)
        if (response.status) {
            await getPendingUsers()
            toast.success('user approval successfull')
            reset()
            handleClose()
        }else{
            toast.error('approval failed')
        }
    }
    return (
        <Modal show={show} onHide={handleClose} size="lg" centered backdrop='static'>
            <Modal.Header closeButton>
                <Modal.Title><h6>Approve User</h6></Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form
                    onSubmit={handleSubmit(submitHandler)}
                >
                    <Row>
                        <Col sm="6" className="mb-20">
                            <Form.Group controlId="name">
                                <Form.Label className="fw-semibold text-primary-light text-sm mb-8">
                                    Email <span className="text-danger-600">*</span>
                                </Form.Label>
                                <Form.Control type="text" placeholder="Enter firstname"
                                    className={`radius-8`} readOnly {...register('email')} />

                            </Form.Group>
                        </Col>
                        <Col sm="6" className="mb-20">

                            <Form.Group controlId="name">
                                <Form.Label className="fw-semibold text-primary-light text-sm mb-8">
                                    Role
                                </Form.Label>
                                <Form.Control as="select" className={`radius-8`} {...register('userRole')}>
                                    <option value='tutor'>Tutor</option>
                                    <option value='student'>Student</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>

                    </Row>

                    <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
                        <Button variant="outline-danger" onClick={handleClose} className="text-md px-56 py-11 radius-8">
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit" className="text-md px-56 py-12 radius-8" disabled={approveUser.isPending}>
                            Approve
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default UserApprovalModal