import React from 'react'
import Login from '../view/auth/Login'


function AuthLayout() {
    return (
        <Login />
    )
}

export default AuthLayout