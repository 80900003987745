import React from 'react'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import DashboardApis from '../../queries/dashboard'
import moment from 'moment'
import { Icon } from '@iconify/react'

function NotificationModal({ show, close,getNotification }) {
    const { data: notificationData} = DashboardApis.GetNotification()
    const readNotification = DashboardApis.ReadNotification()
    const notificationReadhandler = async () => {
        const response = await readNotification.mutateAsync()
        if (response.status) {
            await getNotification()
            close()

        }
    }
    return (
        <Modal show={show} backdrop='static' size='lg' centered>
            <Modal.Body>
                <div className="p-0">
                    <div className="m-16 py-12 px-16 radius-8 bg-primary-50 mb-16 d-flex align-items-center justify-content-between gap-2">
                        <div>
                            <h6 className="text-lg text-primary-light fw-semibold mb-0">Notifications</h6>
                        </div>
                        <span className="text-primary-600 fw-semibold text-lg w-40-px h-40-px rounded-circle bg-base d-flex justify-content-center align-items-center" onClick={notificationReadhandler}>
                            <OverlayTrigger
                                placement='top'
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip>
                                        Mark as read
                                    </Tooltip>
                                }
                            >
                                <Icon icon="solar:check-read-outline" width="24" height="24" style={{ cursor: 'pointer' }} />
                            </OverlayTrigger>

                        </span>
                    </div>

                    <div className="max-h-400-px overflow-y-auto scroll-sm pe-4" style={{ margin: '1em' }}>
                        {
                            notificationData?.data?.notifications?.map((noti, index) => {
                                const maxLength = 100;
                                const truncatedMessage = noti?.message?.length > maxLength
                                    ? noti.message.substring(0, maxLength) + '...'
                                    : noti.message;
                                return (
                                    <a
                                        href="javascript:void(0)"
                                        className={`px-24 py-12 d-flex align-items-start gap-3 mb-2 justify-content-between ${index % 2 === 0 ? "bg-neutral-50" : ""
                                            }`}
                                        key={index}
                                    >
                                        <div className="text-black hover-bg-transparent hover-text-primary d-flex align-items-center gap-3">
                                            <span
                                                className={`w-44-px h-44-px ${index % 2 === 0
                                                    ? "bg-success-subtle text-success-main rounded-circle d-flex justify-content-center align-items-center flex-shrink-0"
                                                    : "w-44-px h-44-px bg-info-subtle text-info-main rounded-circle d-flex justify-content-center align-items-center flex-shrink-0"
                                                    }`}
                                            >
                                                {
                                                    noti.image ?
                                                        <img src={noti?.image} alt="" />
                                                        :
                                                        noti.from.substring(0, 2).toUpperCase()
                                                }

                                            </span>
                                            <div>
                                                <h6 className="text-md fw-semibold mb-4">
                                                    {noti?.from ?? noti?.type}
                                                </h6>
                                                <p className="mb-0 text-sm text-secondary-light text-w-200-px">
                                                    {truncatedMessage}
                                                </p>
                                            </div>
                                        </div>
                                        <span className="text-sm text-secondary-light flex-shrink-0">{moment(noti?.createdAt).format('YYYY-MM-DD')}</span>
                                    </a>
                                );
                            })
                        }


                    </div>



                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='noti-close-btn' onClick={close}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}

export default NotificationModal